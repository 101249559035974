import Button from "../Button";
import Stars from "../Stars";
import ImgModal from "../ImgModal";
import { useState } from "react";

const imgUrl = [
  "/images/envyrinbe/envyrinbe1.png",
  "/images/envyrinbe/envyrinbe2.png",
  "/images/envyrinbe/envyrinbe3.png",
  "/images/envyrinbe/envyrinbe4.png",
];

function Envyrinbe() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="w-full h-screen py-[60px] px-[20px] bg-cover bg-center">
        <div className="max-w-[500px] min-w-[240px] w-full h-full mx-auto rounded-[40px] bg-cover bg-center ">
          <main className="  max-w-[750px] w-full h-full mx-auto  rounded-[40px] overflow-hidden  bg-slate-50 bg-opacity-60">
            <div className="h-full w-full py-[40px] overflow-y-scroll rounded-[40px]">
              <div className=" flex flex-col gap-[20px] items-center ">
                <section className="w-full flex flex-col gap-[10px] ">
                  <button
                    type="button"
                    onClick={() => {
                      setIsOpen(true);
                      console.log(41561156);
                    }}
                    className="relative mx-auto w-[150px] h-[150px] group/img"
                  >
                    <div className="relative mx-auto w-[150px] h-[150px] rounded-full overflow-hidden ">
                      <img
                        src="/images/envyrinbe/envyrinbeProfile.png"
                        alt="프로필"
                        className="relative w-[150px] h-[150px] z-10 rounded-full  bg-cover bg-center group-hover/img:scale-105 transition duration-[500ms] ease-in-out delay-0"
                      />
                    </div>
                    <div className="absolute w-[150px] h-[150px] z-0 top-[5px] left-[5px] rounded-full bg-blue-800 group-hover/img:bg-blue-950 transition duration-[500ms] ease-in-out delay-0" />
                    <div className="absolute w-[150px] h-[150px]  z-20 top-[0px] left-[0px] rounded-full bg-white opacity-0  transition duration-[500ms] ease-in-out delay-0" />
                  </button>
                  <ImgModal
                    open={isOpen}
                    onCancel={() => {
                      setIsOpen(false);
                    }}
                    urls={imgUrl}
                  />
                  <div className="flex flex-col gap-[5px]">
                    <h1 className="text-[30px] font-semibold text-center">댱이</h1>
                    <div className="px-[30px] flex flex-col">
                      <h1 className="text-[20px] font-[600] text-center">Discord : envyrinbe</h1>
                    </div>
                  </div>
                </section>
                <section className="w-full flex flex-col gap-[20px] items-center pb-[20px]">
                  <Button
                    href="https://vrchat.com/home/user/usr_090596f6-8246-49b1-aece-35c10f33fa67"
                    color="bg-blue-800 group-hover:bg-blue-950"
                  >
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">VRChat</p>
                      <img className="w-[25px] h-[25px]" src="/images/vr.svg" alt="vr" />
                    </div>
                  </Button>

                  <Button href="https://x.com/Kerrrreng" color="bg-blue-800 group-hover:bg-blue-950">
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">Twitter</p>
                      <img className="w-[25px] h-[25px]" src="/images/X.svg" alt="트위터" />
                    </div>
                  </Button>

                  <Button href="https://steamcommunity.com/id/vsth42c5/" color="bg-blue-800 group-hover:bg-blue-950">
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">Steam</p>
                      <img className="w-[25px] h-[25px]" src="/images/steam.png" alt="디코방" />
                    </div>
                  </Button>

                  <div className="w-full h-full pl-[20px] pr-[30px] z-0">
                    <div className="relative block group">
                      <div className="w-full h-[50px] px-[20px] flex flex-row  z-0 rounded-[5px] bg-[#d8d8d8] group-hover:translate-x-[3px] group-hover:translate-y-[3px] group-active:translate-x-[6px] group-active:translate-y-[6px]">
                        <div className="w-full flex flex-row items-center justify-between">
                          <div className="w-[30px] h-[30px]" />
                          <p className="text-[20px] font-semibold">416474962</p>
                          <img className="w-[25px] h-[25px]" src="/images/steam.png" alt="디코방" />
                        </div>
                      </div>
                    <div
                    className="bg-blue-800 absolute top-[10px] left-[10px] w-full h-[50px] -z-10  rounded-[5px] transition duration-[500ms] ease-in-out delay-0"
                      ></div>
                    </div>
                  </div>

            
                  
                  <div className="w-full h-full pl-[20px] pr-[30px] z-0">
                    <div className="relative block group">
                      <div className="w-full h-[50px] px-[20px] flex flex-row  z-0 rounded-[5px] bg-[#d8d8d8] group-hover:translate-x-[3px] group-hover:translate-y-[3px] group-active:translate-x-[6px] group-active:translate-y-[6px]">
                        <div className="w-full flex flex-row items-center justify-between">
                          <div className="w-[30px] h-[30px]" />
                          <p className="text-[20px] font-semibold">MoeDevil</p>
                          <img className="w-[25px] h-[25px]"src="https://www.google.com/s2/favicons?domain=https://www.playdeltaforce.com/ko/&sz=128" alt="디코방" />
                        </div>
                      </div>
                    <div
                    className="bg-blue-800 absolute top-[10px] left-[10px] w-full h-[50px] -z-10  rounded-[5px] transition duration-[500ms] ease-in-out delay-0"
                      ></div>
                    </div>
                  </div>

                  <div className="w-full h-full pl-[20px] pr-[30px] z-0">
                    <div className="relative block group">
                      <div className="w-full h-[50px] px-[20px] flex flex-row  z-0 rounded-[5px] bg-[#d8d8d8] group-hover:translate-x-[3px] group-hover:translate-y-[3px] group-active:translate-x-[6px] group-active:translate-y-[6px]">
                        <div className="w-full flex flex-row items-center justify-between">
                          <div className="w-[30px] h-[30px]" />
                          <p className="text-[20px] font-semibold">서린비#Envy</p>
                          <img className="w-[25px] h-[25px]"src="/images/lol.png" alt="디코방" />
                        </div>
                      </div>
                    <div
                    className="bg-blue-800 absolute top-[10px] left-[10px] w-full h-[50px] -z-10  rounded-[5px] transition duration-[500ms] ease-in-out delay-0"
                      ></div>
                    </div>
                  </div>
                  <Button
                    href="https://www.evernote.com/shard/s634/client/snv?isnewsnv=true&noteGuid=8744fb91-04c5-6c1a-c83f-392208d69ad5&noteKey=8d6e68b8ee5cd649a632f7b662d13456&sn=https%3A%2F%2Fwww.evernote.com%2Fshard%2Fs634%2Fsh%2F8744fb91-04c5-6c1a-c83f-392208d69ad5%2F8d6e68b8ee5cd649a632f7b662d13456&title=%EC%9E%90%EC%8B%A0%EC%9D%84%2B%EC%95%84%EA%BB%B4%EC%A3%BC%EC%84%B8%EC%9A%94"
                    color="bg-blue-800 group-hover:bg-blue-950"
                  >
                    <div className="w-full flex flex-row items-center justify-between">
                      <div className="w-[30px] h-[30px]" />
                      <p className="text-[20px] font-semibold">닉네임 정리</p>
                      <img className="w-[25px] h-[25px]" src="/images/evernote.svg" alt="디코방" />
                    </div>
                  </Button>
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>

      <Stars />
    </>
  );
}

export default Envyrinbe;
// bg-fixed bg-center
